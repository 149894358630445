import axios from 'axios';
import { httpErrorHandler } from './middleware/httpErrorHandler';

export default function setAuthToken(token,setCheckConditions) {
  try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
       
      } else {
        delete axios.defaults.headers.common['Authorization'];
      }
    } catch (error) {
      httpErrorHandler(error,setCheckConditions)
    }
  }